<template>
 <tr  style="">
    
      <td>{{ workspace.name }}</td>
      <td>{{ taskssummary.myTasks[1] }}</td>
      <td>{{ taskssummary.myTasks[2] }}</td>
      <td>{{ taskssummary.myTasks[3] }}</td>
      <td>{{ taskssummary.myTasks[4] }}</td>
      <td>{{ taskssummary.myTasks[5] }}</td>
      <td><button class="btn btn-sm btn-primary" @click="gotoCiso(workspace.tenant_uid)">Go to CISO</button></td>
  </tr>
</template>
<script>
export default {
  data() {
    return {
      tenantuid: this.workspace.tenant_uid,
      taskssummary: [],
    };
  },
  props: {
    workspace: Object,
  },
  watch: {
    tenantuid() {
      this.getTaskSummary(this.tenantuid);
    },
  },
  mounted() {
    this.getTaskSummary(this.tenantuid);
  },
  methods: {
    gotoCiso(tenantid) {
      this.$store.commit("setTenantid",tenantid);
      window.open(process.env.VUE_APP_CISOGRC_WEB_URL);
    },
    getTaskSummary(tenantuid) {
      // console.log(process.env.VUE_APP_BASEURL)
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          cid: tenantuid,
        },
        url: `${process.env.VUE_APP_CISOGRC_API_URL}/tasks/summary`,
      };
      this.$http(options)
        .then((resp) => {
          this.taskssummary = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>
<style>
.taskcount {
  text-align: center;
}
</style>
