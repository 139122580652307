<template>
<div>
 <div class="card bg-card p-1">
      <div class="row mb-1">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center">
            <input
              v-model="searchPeriodic"
              placeholder="Search"
              class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
              style="max-width: 200px"
            />
           
          </div>
        </div>
      </div>
    </div>
<div class="card p-2">
    <table class="table border mb-1">
              <thead>
                <tr>
                  <th scope="col">Organization</th>
                  <th scope="col">Pending</th>
                  <th scope="col">In Progress</th>
                  <th scope="col">In Review</th>
                  <th scope="col">Approved</th>
                  <th scope="col">Rejected</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                v-for="(workspace) in this.$store.state.auth.workspaces"
                :key="workspace"
              >
                <workspacecard :workspace="workspace"  />
              </tbody>
            </table>
            </div>
            </div>
</template>
<script>
import workspacecard from "./WorkspaceCard.vue";
export default {
    components: {
        workspacecard
    }
}
</script>
<style scoped>
th{
  background: #472183 !important;
  color: #fff;
}
</style>